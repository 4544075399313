* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    text-decoration: none;
}
  
html, body {
    max-width: 100vw;
    overflow-x: hidden;
    font-family: 'Lato', sans-serif;
}

::-webkit-scrollbar-thumb {
    background: #A2A2A2;
    border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
    background: #fff;
    transition: 0.3s;
}

.main-container {
    background-color: #F2F3F7;
}

.alunos-main {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10% 10% 5% 10%;
    row-gap: 30px;
}

.table-container {
    width: 100%;
    overflow-x: auto;
  }
  
  .tabela-alunos {
    width: 100%;
    min-width: 1000px; /* Define uma largura mínima para evitar que a tabela fique muito pequena */
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    overflow-x: auto;
    max-height: 400px;
  }

.tabela-alunos th,
.tabela-alunos td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.tabela-alunos th {
    background-color: #072955;
    color: white;
}

.tabela-alunos td {
    background-color: #f2f2f2;
}

.forms {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; 
    gap: 30px; 
}

.form-alunos {
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form-h2 {
    text-align: center;
}

.form-alunos label {
    padding-top: 12px;
    padding-bottom: 5px;
    color: #1250a1;
    font-weight: 600;
}

.form-alunos input {
    padding-left: 10px;
    height: 37px;
}

.form-alunos button {
    width: 70%;
    height: 45px;
    margin-top: 25px;
    align-self: center;
    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 20px 0 rgba(20, 34, 223, 0.37);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 8px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    padding-left: 5px;
    font-size: 14px;
    cursor: pointer;
    color: #1250a1;
    font-weight: 600;
}

.message {
    position: absolute;
    width: 100%;
    max-width: 600px;
    background-color: #4caf50;
    color: #fff;
    text-align: center;
    padding: 10px;
    margin-top: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.search-form-container {
    margin: 20px 0;
    padding: 10px;
    border: none;
    border-radius: 8px;
    background-color: transparent;
  }
  
  .search-form {
    display: flex;
    gap: 10px;
  }
  
  .search-form label {
    font-weight: bold;
  }
  
  .search-form input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .search-form button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .search-form button:hover {
    background-color: #0056b3;
  }
  
  .search-form div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  

  .export-btn-disabled {
    background-color: #ccc; /* Cor de fundo cinza */
    cursor: not-allowed; /* Cursor para indicar desativado */
  }
  
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Cor de fundo com transparência */
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    z-index: 1000; /* Colocar sobre outros elementos */
  }
  

@media screen and (min-width: 700px) {
    .alunos-main {
        padding: 10% 10% 10% 10%;
    }
    
    .tabela-alunos {
        margin-bottom: 0;
    }

    .forms {
        justify-content: flex-start;
    }
    
    .form-alunos {
        width: 45%;
        height: 400px;
        border-radius: 0% 10% 10% 10%;
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
        padding: 7%;
    }
}

@media screen and (min-width: 1300px) {
    .alunos-main {
        padding: 10% 10% 5% 10%;
    }
    .form-alunos {
        width: 47%;
        padding: 5%;
    }
}

@media screen and (min-width: 1880px) {
    .alunos-main {
        padding: 5% 10% 5% 10%;
    }
    .form-alunos {
        padding: 5%;
        height: 590px;
    }
}
