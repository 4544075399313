.nav-main {
    position: fixed;;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    display: flex;
    background-color: rgba(0, 0, 0, 0.8); 
    align-items: center;
    justify-content: space-between;
    padding-inline: 15px;
    z-index: 999;
}

.nav-logo {
    width: 70px;
    height: 45px;
    cursor: pointer;
}

.nav-logo:hover {
    transform: scale(1.05); 
    transition: transform 0.3s ease; 
}

.nav-btn {
    width: 80px;
    height: 30px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-size: 14px;
    font-weight: 600;
    margin-top: 0px;
}

.sair-img {
    width: 20px;
    height: 20px;
}
