.cupom-main {
    width: 100%;
    height: 750px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 30% 10% 10% 10%;
}

.form-banner-cupom {
    width: 95%;
    height: 550px;
    border-radius: 0% 10% 10% 10%;
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10%;
}

.form-h2 {
    text-align: center;
}

.form-banner-cupom label {
    padding-top: 15px;
    padding-bottom: 0px;
    color: #1250a1;
    font-weight: 600;
}

.form-banner-cupom input {
    padding-left: 10px;
    height: 40px;
}

.form-banner-cupom select {
    padding-left: 10px;
    height: 40px;
}

.file {
    height: 50px;
    padding-left: 0px !important;
}

.form-banner-cupom button {
    width: 80%;
    height: 45px;
    margin-top: 25px;
    align-self: center;
    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 20px 0 rgba(20, 34, 223, 0.37);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 8px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    padding-left: 5px;
    font-size: 14px;
    cursor: pointer;
    color: #1250a1;
    font-weight: 600;
}

@media screen and (min-width: 1300px) {
    .cupom-main {
        width: 100%;
        height: 800px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 10% 10% 10% 10%;
    }
    .form-banner-cupom {
        width: 80%;
        height: 700px;
        border-radius: 0% 10% 10% 10%;
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 9%;
    }
    
    .form-h2 {
        text-align: center;
    }
    
    .form-banner-cupom label {
        padding-top: 15px;
        padding-bottom: 0px;
        color: #1250a1;
        font-weight: 600;
    }
    
    .form-banner-cupom input {
        padding-left: 10px;
        height: 40px;
    }
    
    .form-banner-cupom select {
        padding-left: 10px;
        height: 40px;
    }
    
    .file {
        height: 50px;
        padding-left: 0px !important;
    }
    
    .form-banner-cupom button {
        width: 60%;
        height: 45px;
        margin-top: 25px;
        align-self: center;
        background: rgba( 255, 255, 255, 0.25 );
        box-shadow: 0 8px 20px 0 rgba(20, 34, 223, 0.37);
        backdrop-filter: blur( 4px );
        -webkit-backdrop-filter: blur( 4px );
        border-radius: 8px;
        border: 1px solid rgba( 255, 255, 255, 0.18 );
        padding-left: 5px;
        font-size: 20px;
        cursor: pointer;
        color: #1250a1;
        font-weight: 600;
    }
}

@media screen and (min-width: 1440px) {
    .cupom-main {
        width: 100%;
        height: 800px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 10% 10% 10% 10%;
    }
    .form-banner-cupom {
        width: 60%;
        height: 750px;
        border-radius: 0% 10% 10% 10%;
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 5% 3% 5% 3%;
    }
    
    .form-h2 {
        text-align: center;
    }
    
    .form-banner-cupom label {
        padding-top: 13px;
        padding-bottom: 0px;
        color: #1250a1;
        font-weight: 600;
    }
    
    .form-banner-cupom input {
        padding-left: 10px;
        height: 42px;
    }
    
    .form-banner-cupom select {
        padding-left: 10px;
        height: 40px;
    }
    
    .file {
        height: 50px;
        padding-left: 0px !important;
    }
    
    .form-banner-cupom button {
        width: 60%;
        height: 45px;
        margin-top: 25px;
        align-self: center;
        background: rgba( 255, 255, 255, 0.25 );
        box-shadow: 0 8px 20px 0 rgba(20, 34, 223, 0.37);
        backdrop-filter: blur( 4px );
        -webkit-backdrop-filter: blur( 4px );
        border-radius: 8px;
        border: 1px solid rgba( 255, 255, 255, 0.18 );
        padding-left: 5px;
        font-size: 20px;
        cursor: pointer;
        color: #1250a1;
        font-weight: 600;
    }
}