*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    text-decoration: none;
}
  
html, body {
    max-width: 100vw;
    overflow-x: hidden;
    font-family: 'Lato', sans-serif;
}

::-webkit-scrollbar-thumb {
    background: #A2A2A2;
    border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
    background: #fff;
    transition: 0.3s;
}

.main-container {
    background-color: #F2F3F7;
}

.termos-main {
    width: 100%;
    height: 850px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 30% 10% 10% 10%;
    row-gap: 30px;
}

.form-termos {
    width: 95%;
    height: 700px;
    border-radius: 0% 10% 10% 10%;
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10%;
}

.form-termos h2 {
    text-align: center;
}

.form-termos label {
    padding-top: 15px;
    padding-bottom: 5px;
    color: #1250a1;
    font-weight: 600;
}


.form-termos textarea {
    padding-left: 10px;
    height: 40px;
}


.form-termos button {
    width: 70%;
    height: 45px;
    margin-top: 25px;
    align-self: center;
    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 20px 0 rgba(20, 34, 223, 0.37);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 8px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    padding-left: 5px;
    font-size: 14px;
    cursor: pointer;
    color: #1250a1;
    font-weight: 600;
}

@media screen and (min-width: 1024px) {
    .termos-main {
        height: 850px;
        padding: 20% 10% 10% 10%;
        row-gap: 30px;
    }
    
    .form-termos {
        width: 95%;
        height: 800px;
        padding: 10%;
    }
    
    .form-termos label {
        padding-top: 15px;
        padding-bottom: 5px;
        color: #1250a1;
        font-weight: 600;
    }
    
    
    .form-termos textarea {
        padding: 5px;
        height: 80px;
    }

}

@media screen and (min-width: 1300px) {
    .termos-main {
        height: 850px;
        padding: 10% 10% 10% 10%;
        row-gap: 30px;
    }
    
    .form-termos {
        width: 95%;
        height: 800px;
        padding: 10%;
    }
    
    .form-termos label {
        padding-top: 15px;
        padding-bottom: 5px;
        color: #1250a1;
        font-weight: 600;
    }
    
    
    .form-termos textarea {
        padding: 5px;
        height: 80px;
    }

    .form-termos button {
        width: 40%;
    }    

}

@media screen and (min-width: 1880px) {
    .termos-main {
        height: 850px;
        padding: 10% 10% 10% 10%;
        row-gap: 30px;
    }
    
    .form-termos {
        width:60%;
        height: 920px;
        padding: 4% 7% 2% 7%;
    }

    .form-termos h2 {
        margin-bottom: 3%;
    }
    
    .form-termos label {
        padding-top: 15px;
        padding-bottom: 5px;
        color: #1250a1;
        font-weight: 600;
    }
    
    
    .form-termos textarea {
        padding: 5px;
        height: 110px;
    }

    .form-termos button {
        width: 30%;
        height: 55px;
        margin-bottom: 5%;
    }    

}

