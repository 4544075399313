*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    text-decoration: none;
}
  
html, body {
    max-width: 100vw;
    overflow-x: hidden;
    font-family: 'Lato', sans-serif;
}

::-webkit-scrollbar-thumb {
    background: #A2A2A2;
    border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
    background: #fff;
    transition: 0.3s;
}

.main-container {
    background-color: #F2F3F7;
}

.produtos-main {
    width: 100%;
    height: 1050px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 30% 10% 10% 10%;
    row-gap: 30px;
}

.form-produtos {
    width: 95%;
    height: 780px;
    border-radius: 0% 10% 10% 10%;
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10%;
}

.form-h2 {
    text-align: center;
}

.form-produtos label {
    padding-top: 15px;
    padding-bottom: 5px;
    color: #1250a1;
    font-weight: 600;
}

.form-produtos input {
    padding-left: 10px;
    height: 40px;
}

.form-produtos select {
    padding-left: 10px;
    height: 40px;
}

.form-produtos textarea {
    padding-left: 10px;
    height: 40px;
}

.file {
    height: 40px;
    padding-left: 0px !important;
}

.form-produtos button {
    width: 70%;
    height: 45px;
    margin-top: 25px;
    align-self: center;
    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 20px 0 rgba(20, 34, 223, 0.37);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 8px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    padding-left: 5px;
    font-size: 14px;
    cursor: pointer;
    color: #1250a1;
    font-weight: 600;
}

.tabela-produtos {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
}

.tabela-produtos th,
.tabela-produtos td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.tabela-produtos th {
    background-color: #042755;
    color: #fff;
}

.tabela-produtos tbody tr:hover {
    background-color: #f2f2f2;
}

@media screen and (max-width: 600px) {
    .tabela-produtos {
        overflow-x: auto;
        display: block;
    }

    .tabela-produtos thead,
    .tabela-produtos tbody,
    .tabela-produtos th,
    .tabela-produtos td,
    .tabela-produtos tr {
        display: block;
    }

    .tabela-produtos tbody tr {
        margin-bottom: 10px;
        border: none;
    }

    .tabela-produtos td {
        padding-left: 50%;
        text-align: left;
        position: relative;
    }

    .tabela-produtos td:before {
        position: absolute;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
    }

    .tabela-produtos td:nth-of-type(1):before {
        content: "Nome: ";
        font-weight: bold;
    }

    .tabela-produtos td:nth-of-type(2):before {
        content: "Preço: ";
        font-weight: bold;
    }

    .tabela-produtos td:nth-of-type(3):before {
        content: "Descrição: ";
        font-weight: bold;
    }

    .tabela-produtos td:nth-of-type(4):before {
        content: "Condição de pagamento: ";
        font-weight: bold;
    }

    .tabela-produtos td:nth-of-type(5):before {
        content: "Link do produto: ";
        font-weight: bold;
    }

    .tabela-produtos td:nth-of-type(6):before {
        content: "Categoria: ";
        font-weight: bold;
    }

    .tabela-produtos td:nth-of-type(7):before {
        content: "Subcategoria: ";
        font-weight: bold;
    }
}


@media screen and (min-width: 700px) {
    .produtos-main {
        padding: 20% 10% 10% 10%;
        height: 900px;
    }
    
    .form-produtos {
        height: 750px;
        padding: 10%;
    }
}

@media screen and (min-width: 1024px) {
    .produtos-main {
        padding: 10% 10% 10% 10%;
        height: 1050px;
    }
    
    .form-produtos {
        width: 70%;
        height: 1000px;
        padding: 5%;
    }
    
    .form-h2 {
        font-size: 32px;
        text-align: center;
    }
    
    .form-produtos label {
        padding-top: 25px;
        padding-bottom: 5px;
        font-size: 18px;
    }
    
    .form-produtos input {
        padding-left: 10px;
        height: 60px;
    }
    
    .form-produtos select {
        padding-left: 10px;
        height: 60px;
    }
    
    .form-produtos textarea {
        padding: 10px;
        height: 180px;
    }
    
    .file {
        height: 60px;
        padding-left: 0px !important;
    }
    
    .form-produtos button {
        width: 50%;
        height: 75px;
        font-size: 18px;
    }
}

@media screen and (min-width: 1300px) {
    
    .form-produtos {
        width: 50%;
    }
    
}

@media screen and (min-width: 1440px) {
    
  .form-produtos button {
    margin-top: 45px;
    height: 90px;
  }
    
}

@media screen and (min-width: 1880px) {
    
    .produtos-main {
        padding: 5% 10% 5% 10%;
        height: 1300px;
    }

    .form-produtos label {
        padding-top: 6px;
        padding-bottom: 18px;
        font-size: 18px;
    }

    .form-produtos {
        height: 1100px;
        padding-bottom: 2%;
        padding-top: 2%;
    }

    .form-produtos input {
        padding-left: 10px;
        height: 40px;
    }
    
    .form-produtos select {
        padding-left: 10px;
        height: 40px;
    }
    
    .form-produtos textarea {
        padding: 10px;
        height: 120px;
    }

    .form-produtos button {
        margin-top: 25px;
        height: 60px;
      }
      
  }