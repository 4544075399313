*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    text-decoration: none;
}
  
html, body {
    max-width: 100vw;
    overflow-x: hidden;
    font-family: 'Lato', sans-serif;
}

::-webkit-scrollbar-thumb {
    background: #A2A2A2;
    border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
    background: #fff;
    transition: 0.3s;
}

.main-container {
    background-color: #F2F3F7;
}


.banner-main {
    width: 100%;
    height: 750px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 30% 10% 10% 10%;
}

.form-banner {
    width: 95%;
    height: 400px;
    border-radius: 0% 10% 10% 10%;
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10%;
}

.form-h2 {
    text-align: center;
}

.form-banner label {
    padding-top: 15px;
    padding-bottom: 0px;
    color: #1250a1;
    font-weight: 600;
}

.form-banner input {
    padding-left: 10px;
    height: 40px;
}

.form-produtos select {
    padding-left: 10px;
    height: 40px;
}

.file {
    height: 50px;
    padding-left: 0px !important;
}

.form-banner button {
    width: 70%;
    height: 45px;
    margin-top: 25px;
    align-self: center;
    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 20px 0 rgba(20, 34, 223, 0.37);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 8px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    padding-left: 5px;
    font-size: 14px;
    cursor: pointer;
    color: #1250a1;
    font-weight: 600;
}


@media screen and (min-width: 768px) {
    
    .form-banner {
        width: 95%;
        height: 400px;
        border-radius: 0% 10% 10% 10%;
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 7%;
    }
    .form-h2 {
        text-align: center;
        font-size: 28px;
    }

}

@media screen and (min-width: 1024px) {
    .banner-main {
        padding: 10% 10% 5% 10%;
    }
    
    .form-banner {
        width: 70%;
        height: 400px;
        border-radius: 0% 10% 10% 10%;
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 7%;
    }
}

@media screen and (min-width: 1300px) {
    
    .form-banner {
        width: 60%;
        height: 400px;
        padding: 5%;
    }
    .form-h2 {
        text-align: center;
        font-size: 30px;
    }
    
    .form-banner label {
        font-size: 18px;
    }
    
    .form-banner input {
        padding-left: 10px;
        height: 40px;
    }
    
    .form-banner button {
        width: 60%;
        height: 45px;
        margin-top: 25px;
        align-self: center;
        background: rgba( 255, 255, 255, 0.25 );
        box-shadow: 0 8px 20px 0 rgba(20, 34, 223, 0.37);
        backdrop-filter: blur( 4px );
        -webkit-backdrop-filter: blur( 4px );
        border-radius: 8px;
        border: 1px solid rgba( 255, 255, 255, 0.18 );
        padding-left: 5px;
        font-size: 18px;
        cursor: pointer;
        color: #1250a1;
        font-weight: 600;
    }
}

@media screen and (min-width: 1880px) {
    
    .form-banner {
        width: 50%;
        height: 450px;
        padding: 5%;
    }
    .form-h2 {
        font-size: 32px;
    }
    
    .form-banner label {
        font-size: 20px;
    }

}