* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    text-decoration: none;
}
  
html, body {
    max-width: 100vw;
    overflow-x: hidden;
    font-family: 'Lato', sans-serif;
}

::-webkit-scrollbar-thumb {
    background: #A2A2A2;
    border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
    background: #fff;
    transition: 0.3s;
}

.main-container {
    background-color: #F2F3F7;
}

.addCategoria-main {
    width: 100%;
    height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10% 5%;
    background-color: #fff;
    overflow-x: auto; 
    margin-top: 20%;
    padding-inline: 10%;
}

.form-addCategoria {
    width: 95%;
    height: 360px;
    border-radius: 0% 10% 10% 10%;
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 7% 10% 7% 10%;
}

.formAddCategoria-h2 {
    text-align: left;
    padding-top: 15px;
}

.form-addCategoria label {
    padding-top: 5px;
    color: #1250a1;
    font-weight: 600;
}

.form-addCategoria p {
    color: gray;
    font-size: 14px;
    margin-top: -25px;
}

.form-addCategoria input {
    padding: 5px;
    height: 36px;
}

.form-addCategoria select {
    padding-left: 10px;
    height: 40px;
}

.form-addCategoria button {
    width: 75%;
    height: 45px;
    margin-top: 5px;
    align-self: center;
    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 20px 0 rgba(20, 34, 223, 0.37);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 8px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    font-size: 14px;
    cursor: pointer;
    color: #1250a1;
    font-weight: 600;
}

@media screen and (min-width: 1024px) {
    .addCategoria-main {
        padding: 1% 5%;
        margin-top: 10%;
        height: 700px;
        flex-direction: row;
        column-gap: 7%;
    }
    
    .form-addCategoria {
        width: 85%;
        height: 500px;
        justify-content: space-evenly;
        padding: 7% 5% 7% 6%;
    }

    .form-addCategoria label {
        padding-top: 5px;
        color: #1250a1;
        font-weight: 600;
        font-size: 22px;
    }
    
    .form-addCategoria p {
        font-size: 16px;
        margin-top: -10px;
    }
    
    .form-addCategoria button {
        width: 65%;
        font-size: 16px;
    }
}

@media screen and (min-width: 1300px) {
    .addCategoria-main {
        padding: 1% 5%;
        margin-top: 5%;
        height: 700px;
    }
    
    .form-addCategoria {
        width: 75%;
        height: 510px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-inline: 8%;
    }
    
    .form-addCategoria p {
        font-size: 16px;
        margin-top: -10px;
    }

    .form-addCategoria h2 {
        font-size: 28px;
    }

    .form-addCategoria label {
        font-size: 22px;
    }
    
    .form-addCategoria button {
        width: 60%;
        margin-top: 10px;
        font-size: 18px;
    }
}

@media screen and (min-width: 1880px) {
    .addCategoria-main {
        column-gap: 0%;
        justify-content: space-evenly;
    }
    .form-addCategoria {
        width: 35%;
        height: 520px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-inline: 3%;
    }
    
    .form-addCategoria p {
        font-size: 16px;
        margin-top: -10px;
    }

    .form-addCategoria h2 {
        font-size: 28px;
    }

    .form-addCategoria label {
        font-size: 22px;
    }
    
    .form-addCategoria button {
        width: 55%;
        margin-top: 10px;
        font-size: 18px;
    }
}





