* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    text-decoration: none;
}
  
html, body {
    max-width: 100vw;
    overflow-x: hidden;
    font-family: 'Lato', sans-serif;
}

::-webkit-scrollbar-thumb {
    background: #A2A2A2;
    border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
    background: #fff;
    transition: 0.3s;
}

.main-container {
    background-color: #F2F3F7;
}

.parceiros-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10% 5%;
    background-color: #fff;
    overflow-x: auto; 
    margin-top: 20%;
    padding-inline: 5%;
}

.tabela-parceiros {
    width: 95%;
    border-collapse: collapse;
    margin-top: 20px;
    font-family: Arial, sans-serif;
    font-size: 14px;
}

.tabela-parceiros th, .tabela-parceiros td {
    border: 1px solid #dddddd;
    padding: 10px;
    text-align: left;
}

.tabela-parceiros th {
    background-color: rgb(5, 5, 69);
    color: #fff;
}

.tabela-parceiros tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.tabela-parceiros tbody tr:hover {
    background-color: #e0e0e0;
}

button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #F2F3F7;
    color: #000;
    border: none;
    border-radius: 4px;
}

button:hover {
    background-color: rgb(5, 5, 65);
    color: #fff;
}

@media screen and (min-width: 768px) {
    .parceiros-main {
        padding: 10% 10%;
    }
}

/* style.css */
@media screen and (min-width:1024px) {
    .parceiros-main {
        margin-top: 0%;
        padding-inline: 5%;
    }
.tabela-parceiros {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-family: Arial, sans-serif;
    font-size: 14px;
  }
  
  .tabela-parceiros th, .tabela-parceiros td {
    border: 1px solid #dddddd;
    padding: 10px;
    text-align: left;
  }
  
  .tabela-parceiros th {
    background-color: rgb(5, 5, 69);
    color: #fff;
  }
  
  .tabela-parceiros tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .tabela-parceiros tbody tr:hover {
    background-color: #e0e0e0;
  }
  
  button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #F2F3F7;
    color: #000;
    border: none;
    border-radius: 4px;
  }
  
  button:hover {
    background-color: rgb(5, 5, 65);
    color: #fff;
  }
} 