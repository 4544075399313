.botoes-cat {
    width: 100%;
    height: 750px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30% 10% 10% 10%;
}

.btn-categ, .btn-subcateg {
    min-width: 200px;
    height: 100px;
    border-radius: 0% 10% 10% 10%;
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 15px;
    cursor: pointer;
    font-size: 22px;
    font-weight: 600;
    color: rgb(4, 4, 74);
}

.btn-categ:hover, .btn-subcateg:hover {
    transform: scale(1.05); 
    transition: transform 0.3s ease; 
}

@media screen and (min-width: 1024px) {
    .botoes-cat {
        height: 750px;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 20% 10% 10% 10%;
    }
    
    .btn-categ, .btn-subcateg {
        min-width: 331px;
    
        justify-content: center;
        align-items: center;
    }
}

@media screen and (min-width: 1300px) {
    .botoes-cat {
        justify-content: space-evenly;
    }
    
    .btn-categ, .btn-subcateg {
        min-width: 331px;
    
        justify-content: center;
        align-items: center;
    }
}

@media screen and (min-width: 1880px) {
    .botoes-cat {
        justify-content: center;
        column-gap: 55px;
        padding: 10% 10% 10% 10%;
    }
    
    .btn-categ, .btn-subcateg {
        min-width: 331px;
    
        justify-content: center;
        align-items: center;
    }
}


